<template>
<div class="inner-template">
  <h1 class="ml-4 my-4">Proposals</h1>
    <div class="overview component" :class="{'mobile': $vuetify.breakpoint.smAndDown}">
      <v-container fill-height fluid class="align-start">
        <v-card color="white" class="ma-0 col-12 mb-2" elevation="4">
        <div class="mb-8 col-12 py-0 px-1">
          <!-- <h5 class="app-heading mb-1"><v-icon class="mr-2" color="success">mdi-calendar-plus</v-icon>Proposed Meetings</h5> -->
          <!-- <h5 class="app-heading mb-1"><v-icon class="mr-2" color="success">mdi-calendar-multiple-check</v-icon>Recent Meetings</h5> -->


          <template v-if="!proposalsLoaded">
            <h5 class="app-heading mb-1">Incoming</h5>
            <v-card class="d-block align-center pa-2">
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            </v-card>
            <h5 class="app-heading mb-1">Outgoing</h5>
            <v-card class="d-block align-center pa-2">
                <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
            </v-card>
          </template>

          <template v-else>
            <h4 class="app-heading grey--text text--darken-1 mb-1">Incoming</h4>
            <!-- no meetings -->
            <template v-if="propAdvisorIsMe.length == 0">
              <v-alert
              color="grey darken-1"
              icon="mdi-calendar-plus"
              text>
              <h4 class="secondary--text">No incoming meetings.</h4>
              <p class="text-body-2">Meetings others requested with you will appear here.</p>
            </v-alert>
          </template>

          <!-- meetings -->
            <div class="proposal py-2 px-0  pa-md-2 mb-0" v-for="(prop, i) in propAdvisorIsMe" :key="`propC-${i}`" @click="$router.push({name: 'Proposal', params:{proposalId: prop.id}})" >
                <v-row class="item" justify="space-between" no-gutters>
                    <!-- OLD STYLE with 2 bubbles-->
                  <v-col cols="auto" class="mr-2">
                    <v-avatar size="50"><v-img :src="prop.data.client.photoURL_thumb"></v-img></v-avatar>
                    <v-avatar size="50" class="overlap"><v-img :src="prop.data.advisor.photoURL_thumb"></v-img></v-avatar>
                  </v-col>
                  <!-- <v-col cols="auto" class="mr-2">
                    <v-avatar size="50"><v-img :src="prop.data.advisor.photoURL_thumb"></v-img></v-avatar>
                  </v-col> -->
                  <v-col>
                    <div class="top-line primary--text">{{prop.data.advisor.firstName || ''}} {{prop.data.advisor.lastName || ""}}</div>
                    <div class="bottom-line text-truncate text--disabled">
                      <span v-for="(item, index) in prop.data.topics" :key="`top-${index}`">{{item.name}}<template v-if="index != prop.data.topics.length - 1">,&nbsp;</template></span>
                    </div>
                  </v-col>
                  <template v-if="prop.data.turn == 'advisor'">
                    <div class="turn px-2 secondary rounded-pill">your turn</div>
                  </template>
                  <template v-else>
                    <v-col cols="auto" class="chevron d-flex flex-column align-center justify-center">
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-col>
                  </template>
                </v-row>

            </div>


            <h4 class="app-heading grey--text text--darken-1 mb-1 mt-3">Outgoing</h4>
            <!-- no meetings -->
            <template v-if="propClientIsMe.length <= 0">
              <v-alert
                color="grey darken-1"
                icon="mdi-calendar-plus"
                text>
                <h4 class="secondary--text">No requested meetings.</h4>
                <p class="text-body-2">Meetings you have requested with others will appear here.</p>
              </v-alert>
            </template>
            <!-- meetings -->
            <div class="proposal py-2 px-0  pa-md-2" v-for="(prop, i) in propClientIsMe" :key="`propA-${i}`" @click="goTo(prop)" >

                <v-row class="item" justify="space-between" no-gutters>
                  <!-- OLD STYLE with 2 bubbles-->
                <v-col cols="auto" class="mr-4">
                  <v-avatar size="50"><v-img :src="prop.data.client.photoURL_thumb"></v-img></v-avatar>
                  <v-avatar size="50" class="overlap"><v-img :src="prop.data.advisor.photoURL_thumb"></v-img></v-avatar>
                </v-col>
                <!-- <v-col cols="auto" class="mr-2">
                  <v-avatar size="50"><v-img :src="prop.data.client.photoURL_thumb"></v-img></v-avatar>
                </v-col> -->
                <v-col>
                  <div class="top-line primary--text">{{prop.data.client.firstName || ''}} {{prop.data.client.lastName || ""}}</div>
                  <div class="bottom-line text-truncate text--disabled">
                    <span v-for="(item, index) in prop.data.topics" :key="`top-${index}`">{{item.name}}<template v-if="index != prop.data.topics.length - 1">,&nbsp;</template></span>
                  </div>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="auto" class="d-flex flex-column align-center justify-center text--secondary caption">
                  <template v-if="prop.data.sendASAP">
                    <v-icon class="">mdi-clock-fast</v-icon>
                    <div>ASAP</div>
                  </template>
                  <template v-else>
                    <v-icon class="">mdi-calendar</v-icon>
                    <div>{{prop.data.dates.length}} date<template v-if="prop.data.dates.length > 1">s</template></div>
                  </template>
                </v-col>
                <v-col cols="auto" v-else class="chevron d-flex flex-column align-center justify-center">
                  <template v-if="prop.data.turn == 'client'">
                      <div class="turn px-2 secondary rounded-pill">your turn</div>
                  </template>
                  <template v-else>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-col>
              </v-row>
            </div>
          </template>

        </div>
      </v-card>
      </v-container>
    </div>

    <v-dialog
      v-model="propDialog"
      max-width="500"
      persistent
      content-class="proposal-dialog"
      transition="dialog-bottom-transition">
      <proposal :selectedProp="selectedProp" @close-prop="closeProp"></proposal>
    </v-dialog>


</div>
</template>

<script>

import { cloud, db } from '@/firebase';
import { mapGetters } from 'vuex';
const proposal = () => import("@/views/Proposal.vue");

export default {
  name: "Proposals",
  components:{proposal},
  computed:{
     getPercentages(arr){
        return true;
    },
    userId: function (){ return this.$store.state.userId},
    user: function (){ return this.$store.state.user},
    proposals: function (){ return this.$store.state.proposals},
    proposalsLoaded: function (){ return this.$store.state.proposalsLoaded},
    propClientIsMe: function(){
      return this.proposals.filter(item => {return item.data.client.userId === this.userId})
    },
    propAdvisorIsMe: function(){
      return this.proposals.filter(item => {return item.data.advisor.userId === this.userId})
    }
  },
  data(){
    return{
      isLoaded: false,
      propDialog: false,
      selectedProp: null,
      snackbar:{
        show:false,
      },
    }
  },
  methods:{
    whoIsUser(proposal){
      return proposal.data.client.userId === this.userId ? 'client' : 'advisor'
    },
    whoIsOther(proposal){
      process.env.NODE_ENV === "development" ? console.log( 'prop', proposal ) : null;
      return proposal.data.client.userId === this.userId ? 'advisor' : 'client'
    },
    closeProp(){
      this.propDialog = false;
      this.selectedProp = null;
    },
    goTo(prop){
      if(this.$vuetify.breakpoint.smAndDown){
        this.$router.push({name: 'Proposal', params:{proposalId: prop.id}})
      }
      else{
        this.propDialog = true;
        this.selectedProp = prop.id
      }
    }
  },
  mounted(){
    // this.getProposals();
  }
};
</script>


<style lang="scss">
.overview.component{
  // background-color: #f2f2f2;
  position: relative;
  .proposal{
    position: relative;
    // border-radius: 6px;
    cursor: pointer;
    &:hover{
      background: #f2f2f2;
      background: var(--v-primary-lighten5);
    }
    // background: var(--v-primary-base);
    // padding-bottom: -1px;
    .v-avatar{
      border: 2px solid transparent;
    }
    .overlap{
      margin-left: -8px;
      border:2px solid white;
    }
    .top-line{
      font-size: 18px;
      line-height: 22px;
      color: #444;
      font-weight: bold;
    }
    .chevron{
      width: 20px;
    }
    .turn{
      position: absolute;
      top: 15%;
      right: 0;
      font-size: 12px;
      font-weight: 700;
      color: white;
    }
  }

}

</style>
